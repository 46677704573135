.popup {
  width: 520px;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 8px;
  z-index: 10;
  transform: translate(-50%, -50%);
  display: block;
  animation: 7s ease;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@keyframes popup {
  0% {
    top: 0%;
  }
  100% {
    top: 100%;
  }
}

/* Responsive */
@media only screen and (max-width: 756px) {
  .popup {
    top: 35%;
  }
}
@media only screen and (max-width: 556px) {
  .popup {
    width: 400px;
    top: 37%;
  }
}

@media only screen and (max-width: 468px) {
  .popup {
    width: 350px;
    padding: 4px;
    margin: 0 auto;
    top: 35%;
  }
}
@media only screen and (max-width: 358px) {
  .popup {
    width: 300px;
    top: 45%;
  }
}
