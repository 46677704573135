:root {
  --swiper-theme-color: #fff;
}
.swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 8px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 8px)
  );
  display: inline-block;
  background: #989292;
  border: 10px;
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}

.swiper-pagination-bullet-active {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 12px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 12px)
  );
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}
.swiper-pagination-bullet {
  border-radius: 2px;
  border-radius: 100%;
}

@media (min-width: 415px) {
  .perimeter {
    flex-direction: row;
  }
}

body {
  background-color: #c6c7c8a7;
}
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap");
.custom {
  font-family: "League Spartan", sans-serif;
}
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "League Spartan", sans-serif;
}
